import Vue from 'vue'
import Vuetify from 'vuetify/lib'



Vue.use(Vuetify)

export default new Vuetify({
    theme: {
      themes: {
        dark: {
            primary: '#607d8b',
            secondary: '#009688',
            accent: '#99CA5F',
            error: '#f44336',
            warning: '#ffc107',
            info: '#2196f3',
            success: '#8bc34a'
        },
      },
    },
  })
