import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'storgiftlistitem';
const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};

export default {

    loadStoreGiftListItem(context, stor_gf_it_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${stor_gf_it_id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadStoreGiftListItems(context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
            .then(response => {
                context.commit('LOAD_STORE_GIFT_LIST_ITEMS', response.data);
                resolve(response.data)
            })
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadStoreGiftListItemsByList(context, stor_gf_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${stor_gf_id}/itens-by-lista`)
            .then(response => {
                context.commit('LOAD_STORE_GIFT_LIST_ITEMS', response.data);
                resolve(response.data)
            })
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeStoreGiftListItem(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, params)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateStoreGiftListItem(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.put(`${URL_BASE_API}${URL_API}${RESOURCE}/${params.stor_gf_it_id}`, params)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateStoreGiftListItemStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateStoreGiftListItemComprado(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-comprado`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyStoreGiftListItem(context, stor_gf_it_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${stor_gf_it_id}`)
                .then(response => resolve(response))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },
}