import Vue from 'vue'
import Router from 'vue-router'

// import store from '../vuex/store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/LoginComponent.vue')
  },
  {
    path: '/dashboard',
    component: () => import('../views/home/HomeComponent.vue'),
    children: [
      {
        path: '/',
        name: 'dash',
        component: () => import('../views/sistema/dashboard/DashboardComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/empresas',
        name: 'company',
        component: () => import('../views/sistema/companies/CompanyComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/empresa/:comp_id/edit',
        name: 'company.edit',
        component: () => import('../views/sistema/companies/EditCompanyComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/empresa/add',
        name: 'company.add',
        component: () => import('../views/sistema/companies/AddCompanyComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/empresa/:comp_id/detail',
        name: 'company.detail',
        component: () => import('../views/sistema/companies/DetailCompanyComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/filiais',
        name: 'branchs',
        component: () => import('../views/sistema/branches/BranchComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/filiais/add',
        name: 'branch.add',
        component: () => import('../views/sistema/branches/AddBranchComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/filiais/:comp_b_id/edit',
        name: 'branch.edit',
        component: () => import('../views/sistema/branches/EditBranchComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/filiais/:comp_b_id/detail',
        name: 'branch.detail',
        component: () => import('../views/sistema/branches/DetailBranchComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/filiais/:comp_b_id/foto',
        name: 'branch.foto',
        component: () => import('../views/sistema/branches/AddFotoBranchComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/banner-setores',
        name: 'bannersector',
        component: () => import('../views/sistema/banner_sectors/BannerSectorComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/banner-setor/add',
        name: 'bannersector.add',
        component: () => import('../views/sistema/banner_sectors/AddBannerSectorComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/banner-setor/:bnnr_sec_id/edit',
        name: 'bannersector.edit',
        component: () => import('../views/sistema/banner_sectors/EditBannerSectorComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/banners',
        name: 'banners',
        component: () => import('../views/sistema/banners/BannerComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/banner/add',
        name: 'banner.add',
        component: () => import('../views/sistema/banners/AddBannerComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/banner/:bnnr_id/edit',
        name: 'banner.edit',
        component: () => import('../views/sistema/banners/EditBannerComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/banner/:bnnr_id/foto',
        name: 'banner.photo',
        component: () => import('../views/sistema/banners/AddImagemBannerComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/banner/:bnnr_id/fotos',
        name: 'bannerphotos',
        component: () => import('../views/sistema/banner_photos/BannerPhotoComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/banner-foto/:bnnr_ft_id/edit',
        name: 'bannerphotos.edit',
        component: () => import('../views/sistema/banner_photos/EditBannerPhotoComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/banner-foto/add',
        name: 'bannerphotos.add',
        component: () => import('../views/sistema/banner_photos/AddBannerPhotoComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/funcionarios',
        name: 'employees',
        component: () => import('../views/sistema/employees/EmployeeComponent.vue'),
      },
      {
        path: '/funcionario/:empl_id/edit',
        name: 'employee.edit',
        component: () => import('../views/sistema/employees/EditEmployeeComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/funcionario/add',
        name: 'employee.add',
        component: () => import('../views/sistema/employees/AddEmployeeComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/funcionario/:empl_id/detail',
        name: 'employee.detail',
        component: () => import('../views/sistema/employees/DetailEmployeeComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/departamentos',
        name: 'departments',
        component: () => import('../views/sistema/departments/DepartmentComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/departamento/:dept_id/edit',
        name: 'department.edit',
        component: () => import('../views/sistema/departments/EditDepartmentComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/departamento/add',
        name: 'department.add',
        component: () => import('../views/sistema/departments/AddDepartmentComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudo-tipos',
        name: 'contentTypes',
        component: () => import('../views/sistema/content_types/ContentTypeComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudo-tipos/:cont_ty_id/edit',
        name: 'contentTypes.edit',
        component: () => import('../views/sistema/content_types/EditContentTypeComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudo-tipos/add',
        name: 'contentTypes.add',
        component: () => import('../views/sistema/content_types/AddContentTypeComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudo-secoes',
        name: 'contentSections',
        component: () => import('../views/sistema/content_sections/ContentSectionComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudo-secao/:cont_sec_id/edit',
        name: 'contentSections.edit',
        component: () => import('../views/sistema/content_sections/EditContentSectionComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudo-secao/add',
        name: 'contentSections.add',
        component: () => import('../views/sistema/content_sections/AddContentSectionComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudo-categorias',
        name: 'contentCategories',
        component: () => import('../views/sistema/content_categories/ContentCategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudo-categorias/:cont_c_id/edit',
        name: 'contentCategories.edit',
        component: () => import('../views/sistema/content_categories/EditContentCategoryComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudo-categorias/add',
        name: 'contentCategories.add',
        component: () => import('../views/sistema/content_categories/AddContentCategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudos',
        name: 'contents',
        component: () => import('../views/sistema/contents/ContentComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudos/:cont_id/edit',
        name: 'contents.edit',
        component: () => import('../views/sistema/contents/EditContentComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudos/add',
        name: 'contents.add',
        component: () => import('../views/sistema/contents/AddContentComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/conteudos/:cont_id/detail',
        name: 'contents.detail',
        component: () => import('../views/sistema/contents/DetailContentComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudos/:cont_id/foto',
        name: 'contents.foto',
        component: () => import('../views/sistema/contents/AddImagemContentComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudo/:content_id/fotos',
        name: 'contentphotos',
        component: () => import('../views/sistema/content_photos/ContentPhotoComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudo-foto/:cont_ft_id/edit',
        name: 'contentphotos.edit',
        component: () => import('../views/sistema/content_photos/EditContentPhotoComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/conteudo-foto/add',
        name: 'contentphotos.add',
        component: () => import('../views/sistema/content_photos/AddContentPhotoComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/marcas',
        name: 'product-brands',
        component: () => import('../views/sistema/product_brands/ProductBrandComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/marca/:prod_b_id/edit',
        name: 'product-brand.edit',
        component: () => import('../views/sistema/product_brands/EditProductBrandComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/marca/add',
        name: 'product-brand.add',
        component: () => import('../views/sistema/product_brands/AddProductBrandComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/modelos',
        name: 'product-models',
        component: () => import('../views/sistema/product_models/ProductModelComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/modelo/:prod_mo_id/edit',
        name: 'product-model.edit',
        component: () => import('../views/sistema/product_models/EditProductModelComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/modelo/add',
        name: 'product-model.add',
        component: () => import('../views/sistema/product_models/AddProductModelComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/departamentos',
        name: 'product-depts',
        component: () => import('../views/sistema/product_depts/ProductDeptsComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/departamento/:prod_dp_id/edit',
        name: 'product-dept.edit',
        component: () => import('../views/sistema/product_depts/EditProductDeptsComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/departamento/add',
        name: 'product-dept.add',
        component: () => import('../views/sistema/product_depts/AddProductDeptsComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/grupos',
        name: 'product-categories',
        component: () => import('../views/sistema/product_categories/ProductCategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/grupo/:prod_c_id/edit',
        name: 'product-category.edit',
        component: () => import('../views/sistema/product_categories/EditProductCategoryComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/grupo/add',
        name: 'product-category.add',
        component: () => import('../views/sistema/product_categories/AddProductCategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/grupo/:prod_c_id/detail',
        name: 'product-category.detail',
        component: () => import('../views/sistema/product_categories/DetailProductCategoryComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/subgrupos',
        name: 'product-subcategories',
        component: () => import('../views/sistema/product_subcategories/ProductSubcategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/subgrupo/:prod_sc_id/edit',
        name: 'product-subcategory.edit',
        component: () => import('../views/sistema/product_subcategories/EditProductSubcategoryComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/subgrupo/add',
        name: 'product-subcategory.add',
        component: () => import('../views/sistema/product_subcategories/AddProductSubcategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/subgrupo/:prod_sc_id/detail',
        name: 'product-subcategory.detail',
        component: () => import('../views/sistema/product_subcategories/DetailProductSubcategoryComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/medidas',
        name: 'product-measures',
        component: () => import('../views/sistema/product_measures/ProductMeasureComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/medida/:prod_me_id/edit',
        name: 'product-measure.edit',
        component: () => import('../views/sistema/product_measures/EditProductMeasureComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/medida/add',
        name: 'product-measure.add',
        component: () => import('../views/sistema/product_measures/AddProductMeasureComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produtos',
        name: 'products',
        component: () => import('../views/sistema/products/ProductComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produtos/descontos',
        name: 'products-discounts',
        component: () => import('../views/sistema/products/ProductDiscountComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/:prod_id/edit',
        name: 'product.edit',
        component: () => import('../views/sistema/products/EditProductComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/add',
        name: 'product.add',
        component: () => import('../views/sistema/products/AddProductComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/:prod_id/detail',
        name: 'product.detail',
        component: () => import('../views/sistema/products/DetailProductComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/:prod_id/foto',
        name: 'product.foto',
        component: () => import('../views/sistema/products/AddImagemProductComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produtos/cores',
        name: 'product.colors',
        component: () => import('../views/sistema/product_colors/ProductColorComponent.vue'),
        props: true,
        meta: { auth: true }
      },
            {
        path: '/produto-color/add',
        name: 'product.colors.add',
        component: () => import('../views/sistema/product_colors/AddProductColorComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/produto/:prod_id/color',
        name: 'product.color',
        component: () => import('../views/sistema/products/AddColorProductComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto-color/:prod_co_id/edit',
        name: 'product.colors.edit',
        component: () => import('../views/sistema/product_colors/EditProductColorComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/produto/:prod_id/file',
        name: 'product.file',
        component: () => import('../views/sistema/products/AddProductFileComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/usuarios',
        name: 'users',
        component: () => import('../views/sistema/users/UserComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/usuario/:usu_id/detail',
        name: 'user.detail',
        component: () => import('../views/sistema/users/DetailUserComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/usuario/:usu_id/pedidos',
        name: 'user.orders',
        component: () => import('../views/sistema/users/OrderUserComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/cupons',
        name: 'store-coupons',
        component: () => import('../views/sistema/store_coupons/StoreCouponComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/cupom/:stor_co_id/edit',
        name: 'store-coupons.edit',
        component: () => import('../views/sistema/store_coupons/EditStoreCouponComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/cupom/add',
        name: 'store-coupons.add',
        component: () => import('../views/sistema/store_coupons/AddStoreCouponComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/cupom/:stor_co_id/detail',
        name: 'store-coupons.detail',
        component: () => import('../views/sistema/store_coupons/DetailStoreCouponComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/pedidos',
        name: 'store-orders',
        component: () => import('../views/sistema/store_orders/StoreOrderComponent.vue'),
        meta: { auth: true}
      },
      {
        path: '/loja/pedido/:stor_or_id/detail',
        name: 'store-order.detail',
        component: () => import('../views/sistema/store_orders/DetailStoreOrderComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/fretes',
        name: 'store-shippings',
        component: () => import('../views/sistema/store_shippings/StoreShippingComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/frete/:stor_sh_id/edit',
        name: 'store-shipping.edit',
        component: () => import('../views/sistema/store_shippings/EditStoreShippingComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/frete/add',
        name: 'store-shipping.add',
        component: () => import('../views/sistema/store_shippings/AddStoreShippingComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/frete/:stor_sh_id/detail',
        name: 'store-shipping.detail',
        component: () => import('../views/sistema/store_shippings/DetailStoreShippingComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/listas-de-casamento',
        name: 'store-gift-lists',
        component: () => import('../views/sistema/store_gift_lists/StoreGiftListComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/lista-de-casamento/add',
        name: 'store-gift-list.add',
        component: () => import('../views/sistema/store_gift_lists/AddStoreGiftListComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/lista-de-casamento/:stor_gf_id/edit',
        name: 'store-gift-list.edit',
        component: () => import('../views/sistema/store_gift_lists/EditStoreGiftListComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/lista-de-casamento/:stor_gf_id/detail',
        name: 'store-gift-list.detail',
        component: () => import('../views/sistema/store_gift_lists/DetailStoreGiftListComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/lista-de-casamento/:stor_gf_id/item/add',
        name: 'store-gift-list-item.add',
        component: () => import('../views/sistema/store_gift_list_items/StoreGiftListItemComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/lista/categorias',
        name: 'store-gift-list-categories',
        component: () => import('../views/sistema/store_gift_list_categories/StoreGiftListCategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/lista/categoria/:stor_glc_id/edit',
        name: 'store-gift-list-category.edit',
        component: () => import('../views/sistema/store_gift_list_categories/EditStoreGiftListCategoryComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/loja/lista/categoria/add',
        name: 'store-gift-list-category.add',
        component: () => import('../views/sistema/store_gift_list_categories/AddStoreGiftListCategoryComponent.vue'),
        meta: { auth: true }
      },
      {
        path: '/loja/lista/categoria/:stor_glc_id/detail',
        name: 'store-gift-list-category.detail',
        component: () => import('../views/sistema/store_gift_list_categories/DetailStoreGiftListCategoryComponent.vue'),
        props: true,
        meta: { auth: true }
      },
      {
        path: '/newsletters',
        name: 'newsletters',
        component: () => import('../views/sistema/newsletters/NewsletterComponent.vue'),
        meta: { auth: true }
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  //console.log(to)
  //console.log(from)

  /*if (to.meta.auth && !store.state.auth.authenticated) {
      store.commit('CHANGE_URL_BACK', to.name)

      return router.push({name: 'login'})
  }

  if (to.matched.some(record => record.meta.auth) && !store.state.auth.authenticated) {
      store.commit('CHANGE_URL_BACK', to.name)

      return router.push({name: 'login'})
  }

  if (to.meta.hasOwnProperty('auth') && !to.meta.auth && store.state.auth.authenticated) {
      return router.push({name: 'admin.dashboard'})
  }*/

  next()
})

export default router
