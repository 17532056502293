import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth/auth'

import companybranches from './modules/companybranches/companybranch'
import bannersectors from './modules/banner_sectors/bannersector'
import banners from './modules/banners/banner'
import bannerphotos from './modules/banner_photos/bannerphotos'
import employees from './modules/employees/employee'
import languages from './modules/languages/languages'
import companies from './modules/companies/company'
import departments from './modules/departments/departments'
import contenttypes from './modules/content_types/content_types'
import contentsections from './modules/content_sections/content_sections'
import contentcategories from './modules/content_categories/content_categories'
import contents from './modules/contents/contents'
import contentphotos from './modules/content_photos/content_photos'
import productbrands from './modules/product_brands/product_brands'
import productmodels from './modules/product_models/product_models'
import productdepts from './modules/product_depts/product_depts'
import productcategories from './modules/product_categories/product_categories'
import productsubcategories from './modules/product_subcategories/product_subcategories'
import productmeasures from './modules/product_measures/product_measures'
import products from './modules/products/products'
import productphotos from './modules/product_photos/product_photos'
import productcolors from './modules/product_colors/product_colors'
import productfiles from './modules/product_files/product_files'
import users from './modules/users/users'
import storeorders from './modules/store_orders/store_orders'
import storeordersfollow from './modules/store_orders_follow/store_orders_follow'
import storeshippings from './modules/store_shippings/store_shippings'
import storegiftlistcategories from './modules/store_gift_list_categories/store_gift_list_categories'
import storegiftlists from './modules/store_gift_lists/store_gift_lists'
import storegiftlistitems from './modules/store_gift_list_items/store_gift_list_items'
import storecoupons from './modules/store_coupons/store_coupons'
import dashboards from './modules/dashboards/dashboards'
import newsletters from './modules/newsletters/newsletters'
import commons from './modules/commons/commons'
import preloader from './modules/preloader/preloader'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    commons,
    bannersectors,
    bannerphotos,
    banners,
    companies,
    departments,
    employees,
    languages,
    companybranches,
    contenttypes,
    contentsections,
    contentcategories,
    contents,
    contentphotos,
    preloader,
    productbrands,
    productmodels,
    productdepts,
    productcategories,
    productsubcategories,
    productmeasures,
    products,
    productphotos,
    productcolors,
    productfiles,
    users,
    storeorders,
    storeordersfollow,
    storeshippings,
    storegiftlistcategories,
    storegiftlists,
    storegiftlistitems,
    storecoupons,
    dashboards,
    newsletters,
  }
})

export default store
