import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'company';
const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};

export default {

    loadCompanies (context) {
        context.commit('PRELOADER', true)
        axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
            .then(response => {
                context.commit('LOAD_COMPANIES', response.data);
            })
            .catch(error => {
                
            })
            .finally(() => context.commit('PRELOADER', false))
            
    },

    loadCompany(context, comp_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${comp_id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    /*loadCompanyAuth(context) {
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${RESOURCE}-authenticated`)
                .then(response => resolve(context.commit('LOAD_USER_AUTH', response.data)))
                .catch(error => reject())
        })
    },

    
    localStorage.setItem(USER, response.data)
    storeCompany(context, params){
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${RESOURCE}`, params)
                .then(response => resolve())
                .catch(error => reject(error))
        })
    },
    */

    storeCompany(context, formData){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, formData, CONFIGS)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateCompany(context, formData){
        context.commit('PRELOADER', true)
        formData.append('_method', 'PUT');
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/${formData.get('comp_id')}`, formData, CONFIGS)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyCompany(context, comp_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${comp_id}`)
                .then(response => resolve(response))
                .catch(error => reject(reject))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateCompanyStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    }

    /*
    * Adiciona papeis ao usuário, papeis são: Admin, Usuario, Representante, Preposto
    
    storeCompanyRole(context, params){
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${RESOURCE}/role`, params)
                .then(response => resolve())
                .catch(error => reject(error))
        })
    },
    loadRolesByCompany(context, id) {
        axios.get(`${URL_BASE_API}${RESOURCE}/papel/${id}`)
            .then(response => {
                context.commit('LOAD_ROLES', response);
            })
            .catch(errors => {
                console.log(errors)
            })
    },*/
}