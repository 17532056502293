import axios from 'axios'
import {URL_BASE_API} from '../../../config/configs'

export default {
    loadEnderecoByCEP (context, cep) {
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}viacep/${cep}`)
                .then(response => resolve(response))
                .catch(error => reject())
        })
    },
    
    //sem uso
    isMobile (context) {
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}/api/is-agent`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },

}