import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'storeshipping';
const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};

export default {

    loadStoreShipping(context, stor_sh_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${stor_sh_id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadStoreShippings(context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
                .then(response => {
                    context.commit('LOAD_STORE_SHIPPINGS', response.data);
                    resolve(response.data)
                })
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeStoreShipping(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, param)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateStoreShipping(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.put(`${URL_BASE_API}${URL_API}${RESOURCE}/${param.stor_sh_id}`, param)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateStatusStoreShipping(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyStoreShipping(context, stor_sh_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${stor_sh_id}`)
                .then(response => resolve(response))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },
}