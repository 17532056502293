export default {
    LOAD_USERS (state, users) {
        state.items = users
    },
    LOAD_USER_ORDERS (state, users) {
        state.orders = users
    },
    LOAD_USERS_ALL (state, users) {
        state.all = users
    }
}