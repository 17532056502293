import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'newsletter';

export default {

    /**
     * o atributo .data do response.data foi retirado pq ele
     * só funciona se no Laravel for paginate() se for all() tem que retirar
     * @param {*} context 
     */
    loadNewsletters (context) {
        
        return new Promise( (resolve, reject) => {
            context.commit('PRELOADER', true)
        axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
            .then(response => {
                context.commit('LOAD_NEWSLETTERS', response);
                resolve(response)
            })
            .catch(error => reject(error))
            .finally(() => context.commit('PRELOADER', false))
        })
    },   

    updateNewsletterStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    }
}