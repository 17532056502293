export default {
    items: {
        data: null
    },
    variations: {
        data: null
    },
    colors: {
        data: null
    }
}