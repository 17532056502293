import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'dashboard';

export default {

    loadOrdersByMonth (context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
        axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/orders-by-month`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
            .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadBestSellProduct (context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
        axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/best-sell-product`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
            .finally(() => context.commit('PRELOADER', false))
        })
    },

}