import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'productfile';

const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};


export default {

    loadProductFile(context, cont_fi_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${cont_fi_id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadProductFiles(context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
                .then(response => {
                    context.commit('LOAD_CONTENTPHOTOS', response.data);
                    resolve(response.data)
                })
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadProductFilesByProduct(context, cont_fi_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${cont_fi_id}/product`)
                .then(response => {
                    context.commit('LOAD_PHOTOS_BY_PRODUCT', response.data);
                    resolve(response.data)
                })
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProductFile(context, formData){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, formData, CONFIGS)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductFile(context, formData){
        context.commit('PRELOADER', true)
        formData.append('_method', 'PUT');
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/${formData.get('cont_fi_id')}`, formData)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductFileStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyProductFile(context, cont_fi_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${cont_fi_id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    addFilesProductFile(context, formData){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/add-files`, formData, CONFIGS)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },
}