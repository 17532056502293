const protocol = window.location.protocol
const host = window.location.host
const array = host.split('.')
let urlapi = null
let urlbase = null

switch (array.length) {
  case 3:
    urlapi = (host === 'localhost:8080') ? 'http://localhost:8000/' : protocol + '//' + 'api' + '.' + array[1] + '.' + array[2] + '/'
    urlbase = (host === 'localhost:8080') ? 'http://localhost:8000/' : protocol + '//' + array[1] + '.' + array[2] + '/'
    break;
  case 4:
    urlapi = (host === 'localhost:8080') ? 'http://localhost:8000/' : protocol + '//' + 'api' + '.' + array[1] + '.' + array[2] + '.' + array[3] + '/'
    urlbase = (host === 'localhost:8080') ? 'http://localhost:8000/' : protocol + '//' + array[1] + '.' + array[2] + '.' + array[3] + '/'
    break
  case 5:
    urlapi = (host === 'localhost:8080') ? 'http://localhost:8000/' : protocol + '//' + 'api' + '.' + array[1] + '.' + array[2] + '.' + array[3] + '.' + array[4] + '/'
    urlbase = (host === 'localhost:8080') ? 'http://localhost:8000/' : protocol + '//' + array[1] + '.' + array[2] + '.' + array[3] + '.' + array[4] + '/'
    break
    default:
      urlapi = (host === 'localhost:8080') ? 'http://localhost:8000/' : ''
      urlbase = (host === 'localhost:8080') ? 'http://localhost:8000/' : ''
}

//console.log(urlapi)
//console.log(urlbase)
//console.log(array)
//console.log(host)

export const VERSION = '1.0.7.5'
export const URL_BASE_API = urlapi
export const URL_BASE = urlbase
export const URL_API = 'api/v1/'
// export const TOKEN = 'TOKEN_AUTH';

export const IS_MOBILE = () => {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
}
