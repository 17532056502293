import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'product-brand';
const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};

export default {

    /**
     * o atributo .data do response.data foi retirado pq ele
     * só funciona se no Laravel for paginate() se for all() tem que retirar
     * @param {*} context 
     */
    loadProductBrands (context) {
        context.commit('PRELOADER', true)
        axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
            .then(response => {
                context.commit('LOAD_PRODUCT_BRANDS', response);
            })
            .catch(errors => {
                console.log(errors)
            })
            .finally(() => context.commit('PRELOADER', false))
    },

    loadProductBrand(context, prod_b_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_b_id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProductBrand(context, formData){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, formData, CONFIGS)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductBrand(context, formData){
        context.commit('PRELOADER', true)
        formData.append('_method', 'PUT');
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/${formData.get('prod_b_id')}`, formData, CONFIGS)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyProductBrand(context, prod_b_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_b_id}`)
                .then(response => resolve(response))
                .catch(error => reject(reject))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductBrandStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}