import { URL_BASE_API } from '../../../config/configs'
import axios from 'axios'

export default {
  state: {
    user: sessionStorage.getItem('usuario') ? JSON.parse(sessionStorage.getItem('usuario')) : null,
    authenticated: false
  },
  getters: {
    getUsuario: state => {
      return state.user
    },
    getToken: state => {
      return state.user.token
    },
    getUserID: state => {
      return state.user.empl_id
    }
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
    }
  },
  actions: {
    login (context, params) {
      context.commit('PRELOADER', true)
      return new Promise((resolve, reject) => {
        axios.post(`${URL_BASE_API}api/login`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => context.commit('PRELOADER', false))
      })
    }
  }
}
