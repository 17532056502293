import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'product';
const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};

export default {

    loadProduct(context, id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    /*loadProducts(context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
            .then(response => {
                context.commit('LOAD_PRODUCTS', response.data);
                resolve(response.data)
            })
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },*/

    loadProducts(context, params) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`, {params})
            .then(response => {
                context.commit('LOAD_PRODUCTS', response.data);
                resolve(response.data)
            })
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },

    exportProducts(context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/export/excel`)
            .then(response => resolve(response))
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadProductWithPhotos(context, prod_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_id}/photos`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadProductWithColors(context, prod_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_id}/colors`)
            .then(response => {
                context.commit('LOAD_COLORS_BY_PRODUCT', response.data);
                resolve(response.data)
            })
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadProductWithFiles(context, prod_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_id}/files`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProduct(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, params)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    atribuiColorByProduct(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/add-color`, params)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProduct(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.put(`${URL_BASE_API}${URL_API}${RESOURCE}/${params.prod_id}`, params)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },
    
    updateImagemProduct(context, formData){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-imagem`, formData, CONFIGS)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateDiscount(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-discount`, param)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    editProductPrice(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-price`, param)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductShowSite(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-show-site`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductShowPreco(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-show-preco`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductDestaqueHome(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-destaque-home`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyProduct(context, prod_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_id}`)
                .then(response => resolve(response))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    removeColorAtribuida(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/remove-color`, params)
            .then(response => resolve(response.data))
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },
}