import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'product-measure';
const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};

export default {

    loadProductMeasures (context) {
        context.commit('PRELOADER', true)
        axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
            .then(response => {
                context.commit('LOAD_PRODUCT_MEASURES', response.data);
            })
            .catch(errors => {
                console.log(errors)
            })
            .finally(() => context.commit('PRELOADER', false))
    },

    loadProductMeasure(context, prod_me_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_me_id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProductMeasure(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, params)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductMeasure(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.put(`${URL_BASE_API}${URL_API}${RESOURCE}/${params.prod_me_id}`, params)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyProductMeasure(context, prod_me_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_me_id}`)
                .then(response => resolve(response))
                .catch(error => reject(reject))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductMeasureStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}