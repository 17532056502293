export default {
    LOAD_PRODUCTS (state, products) {
        state.items = products
    },
    LOAD_PRODUCT (state, products) {
        state.item = products
    },
    LOAD_COLORS_BY_PRODUCT (state, products) {
        state.colors = products
    }
}