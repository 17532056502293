export default {
    items: {
        data: null
    },
    orders: {
        data: null
    },
    all: {
        data: null
    }
}