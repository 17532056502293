<template>
    <div class="preloader" v-show="preloader">
        <img src="/assets/img/Spinner-1s-200px.apng" alt="Carregando...">
    </div>
</template>

<script>
export default {
  computed: {
    preloader () {
      return this.$store.state.preloader.loading
    }
  }
}
</script>

<style scoped>
.preloader{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: #424242;
    opacity: .7;
    height: 100vh;
    width: 100%;
}
.preloader > img {

}
</style>
