import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import axios from 'axios'
import UUID from 'vue-uuid'
import VueTheMask from 'vue-the-mask'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import VueJsonToCsv from 'vue-json-to-csv'
import '@mdi/font/css/materialdesignicons.css'
import 'sweetalert2/dist/sweetalert2.min.css'

import VueSweetalert2 from 'vue-sweetalert2';

const user = JSON.parse(sessionStorage.getItem('usuario'))
if (user) { axios.defaults.headers.common.Authorization = `Bearer ${user.token}` }

const options = {
  confirmButtonColor: '#01579B',
  cancelButtonColor: '#FF9800'
}

Vue.use(UUID);
Vue.use(VueSweetalert2, options);
Vue.use(VueTheMask);
Vue.use(VueJsonToCsv);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
