import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'productcolor';

const CONFIGS = {
    headers: {
        'content-type': 'multipart/form-data',
    }
};


export default {

    loadProductColor(context, prod_co_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_co_id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadProductColors(context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
                .then(response => {
                    context.commit('LOAD_PRODUCTCOLORS', response.data);
                    resolve(response.data)
                })
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadProductColorsByProduct(context, product_id) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${product_id}/product`)
                .then(response => {
                    context.commit('LOAD_COLORS_BY_PRODUCT', response.data);
                    resolve(response.data)
                })
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeProductColor(context, formData){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, formData, CONFIGS)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductColor(context, formData){
        context.commit('PRELOADER', true)
        formData.append('_method', 'PUT');
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/${formData.get('prod_co_id')}`, formData)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateProductColorStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyProductColor(context, prod_co_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${prod_co_id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    addImagensProductColor(context, formData){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/add-imagens`, formData, CONFIGS)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },
}