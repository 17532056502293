import axios from 'axios'
import {URL_BASE_API, URL_API} from '../../../config/configs'

const RESOURCE = 'storecoupon';

export default {

    loadStoreCoupon(context, id) {
        console.log(id)
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    /*loadStoreCoupons(context) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`)
            .then(response => {
                context.commit('LOAD_COUPONS', response.data);
                resolve(response.data)
            })
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },*/

    loadStoreCoupons(context, params) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}`, {params})
            .then(response => {
                context.commit('LOAD_COUPONS', response);
                resolve(response.data)
            })
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },

    /*loadStoreCouponsWithVariation(context, params) {
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.get(`${URL_BASE_API}${URL_API}${RESOURCE}/${params.prod_id}/with-variation/${params.prod_var_id}`)
            .then(response => {
                context.commit('LOAD_PRODUCT_VARIATION', response.data);
                resolve(response.data)
            })
            .catch(error => reject())
            .finally(() => context.commit('PRELOADER', false))
        })
    },*/

    storeStoreCoupon(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}`, params)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateStoreCoupon(context, params){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.put(`${URL_BASE_API}${URL_API}${RESOURCE}/${params.stor_co_id}`, params)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateStoreCouponStatus(context, param){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.post(`${URL_BASE_API}${URL_API}${RESOURCE}/update-status`, param)
                .then(response => resolve())
                .catch(error => reject(error))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyStoreCoupon(context, stor_co_id){
        context.commit('PRELOADER', true)
        return new Promise( (resolve, reject) => {
            axios.delete(`${URL_BASE_API}${URL_API}${RESOURCE}/${stor_co_id}`)
                .then(response => resolve(response))
                .catch(error => reject())
                .finally(() => context.commit('PRELOADER', false))
        })
    },
}