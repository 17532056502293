<template>
<div>
    <preloader-component></preloader-component>
    <router-view />
</div>
</template>

<script>
import PreloaderComponent from '@/views/sistema/partials/PreloaderComponent'
export default {
  name: 'App',
  components: { PreloaderComponent }
}
</script>

<style>
</style>
